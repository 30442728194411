import { IntlShape } from 'react-intl'

// Playwright uses this file but has problems with basePath from tsconfig
// for whatever reason
import { buildPath } from '../utils/paths'

export const paths = {
  account: '/account',
  accountEditBillingAddress: '/account/membership/billing/edit',
  accountEditPaymentMethod: '/account/membership/payment-method/edit',
  accountEditShippingAddress: '/account/membership/shipping/edit',
  accountJuul2App: '/account/juul2-app',
  accountMembership: '/account/membership',
  accountOrder: (orderNumber: string) => `/account/orders/${orderNumber}`,
  accountOrders: '/account/orders',
  accountProfile: '/account/profile',
  accountProfileOpenEmail: '/account/profile?emailOpen=true',
  accountRecovery: '/account/recovery',
  accountWarranty: '/account/product_registration',
  ageGate: '/age-gate',
  ageVerification: '/account/age-verification',
  ageVerificationJumioNetverifyError: (query) => buildPath('/age_verification/netverify/upload/error', query),
  ageVerificationJumioNetverifyScan: '/age_verification/netverify/scan',
  ageVerificationNextLevel: (redirect?: string) => buildPath(
    '/account/age-verification/request-next-level',
    redirect && { redirect },
  ),
  ageVerificationSuccess: '/account/age-verification/success',
  autoshipBuilder: '/build-your-plan',
  autoshipEditor: '/edit-your-plan',
  autoshipEditorPods: '/edit-your-plan/pods',
  cart: '/cart',
  geofence: '/geofence',
  georestriction: '/georestriction',
  globalPrivacyNotices: 'https://www.juul.com/legal/global-privacy-notices',
  homepage: '/',
  locator: '/locator',
  lossProtection: '/loss_protection',
  oAuthCancel: '/oauth/authorizations/cancel',
  phoneVerification: '/authentication/phone-verification',
  phoneVerificationRenew: '/authentication/phone-verification/renew',
  phoneVerificationSuccess: (redirect?: string) => buildPath(
    '/authentication/phone-verification/success',
    redirect && { redirect },
  ),
  registerProduct: '/account/register',
  resetPassword: '/password/new',
  shop: '/shop',
  signin: '/signin',
  signout: '/signout',
  signoutToSignIn: '/signout?redirectToSignIn=true',
  ssoSignin: '/sso/signin',
  support: '/support',
  supportAgeVerification: (intl: IntlShape) => {
    const { formatMessage } = intl
    return formatMessage({ id: 'age_verification.support_url' })
  },
  supportArticle: (goToPublic: boolean, articleUrlName: string) =>
    `/support/${goToPublic ? 'Pkb' : 'Csp' }/${articleUrlName}`,
  traceabilityReportingBasePath: '/report',
  traceabilityReportingPath: '/report/device',
  traceabilityReportingSuccessPath: '/report/success',
  tracking: '/tracking',
  warranty: (topic: 'accessory' | 'device', id: number) => `/claim-warranty/start?topic=${topic}&productId=${id}`,
}
