import { stores as businessStores } from '@viewlio/config/src/business'
import { StoreType } from '@viewlio/config/src/types'
import { stores as consumerStores } from '@viewlio/config/src/viewlioConfig/stores'

import { clearLocale } from './clearLocale'

export const getStoreByTypeAndLocale = (
  storeType: StoreType, locale: string,
) => {
  const allStores = {
    ...consumerStores,
    ...businessStores,
  }
  return Object.entries(allStores).find(([_store, { locales, type }]) =>
    type === storeType &&
    (locales.includes(locale) || locales.includes(clearLocale(locale))),
  )
}
