import { StoreType } from '../types'

import { stores, ViewlioBusinessStoreConfig } from './stores'

export { stores } from './stores'
export { paths } from './paths'
export type { ViewlioBusinessStore, ViewlioBusinessStoreConfig } from './stores'

export const domains = [
  'partner-$iso.dev.juul.com',
  '$iso.partner.qa1.viewlio.app',
  '$iso.partner.staging.viewlio.app',
  '$iso.partner.stage.viewlio.app',
  '$iso.partner.preprod.viewlio.app',
  '$iso.partner.production.viewlio.app',
  'wholesale-$iso.dev.juul.com',
  '$iso.wholesale.qa1.viewlio.app',
  '$iso.wholesale.staging.viewlio.app',
  '$iso.wholesale.stage.viewlio.app',
  '$iso.wholesale.preprod.viewlio.app',
  '$iso.wholesale.production.viewlio.app',
]

export const storeLocales = (storeType: StoreType) =>
  Object.values(stores)
    .filter((store) => store.type === storeType)
    .flatMap((store: ViewlioBusinessStoreConfig) => store.locales)

